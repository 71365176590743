<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="categoryMasterValidation">
      <b-form>
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              @click="$router.go(-1)"
              ><feather-icon icon="SkipBackIcon" />
              {{ $t("Common.Back") }}
            </b-button>
          </b-col> 
        </b-row>
        <b-row class="mt-2">
          <b-col md="6">
            <b-form-group :label="$t('Category Master List.Category Name')">
              <validation-provider
                #default="{ errors }"
                name="Category Name"
                rules="required"
              >
                <b-form-input
                  v-model="form.category_name"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  :placeholder="$t('Category Master List.Category Name')"
                  :disabled="!$can('write', 'masters')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group :label="$t('Category Master List.Category Code')">
              <validation-provider
                #default="{ errors }"
                name="Category Code"
                rules="required"
              >
                <b-form-input
                  v-model="form.category_code"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  :placeholder="$t('Category Master List.Category Code')"
                  :disabled="!$can('write', 'masters')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group :label="$t('Category Master List.Category Description')">
              <validation-provider
                #default="{ errors }"
                name="Category Description"
                rules=""
              >
                <b-form-textarea
                  v-model="form.category_desc"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  :placeholder="$t('Category Master List.Category Description')"
                  :disabled="!$can('write', 'masters')"
                  rows="3"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="text-center">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="saveCategory"
            >
              {{ $t("Common.Save") }}
            </b-button>
          </b-col> 
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BCard,
  BFormInput,
  BFormGroup, 
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormTextarea,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@validations";
import MasterServices from "@/apiServices/MasterServices";

export default {
  name: "form",
  directives: {
    Ripple,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
  },
  data() {
    return {
      category_id: null,
      form: {
        category_name: "",
        category_code: "",
        category_desc: "",
        category_is_active: true,
      },
      required,
    };
  },
  methods: {
    saveCategory() {
      this.$refs.categoryMasterValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.form.category_id = this.category_id;
            // console.log("this.form=>",this.form);return false;
            const categoryMaster = await MasterServices.saveCategory(this.form);

            if (categoryMaster.data.status) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title:
                    categoryMaster.data.message || "Category Added Successfull",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              this.$router.replace("/masters/category-list");
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: categoryMaster.data.message || "Cannot Added Category",
                  icon: "EditIcon",
                  variant: "failure",
                },
              });
            }
          } catch (error) {
            console.log("Error in saveCategory ", error);
            console.log({ error });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message || "Error in saveCategory",
                icon: "EditIcon",
                variant: "failure",
              },
            });
          }
        }
      });
    },
    async getSingleCategory(category_id) {
      try {
        const response = await MasterServices.getSingleCategory(category_id);
        let singleCategory = response.data.data;
        if (response.data.status) {
          this.form = {
            category_name: singleCategory.category_name,
            category_code: singleCategory.category_code,
            category_desc: singleCategory.category_desc,
            category_is_active: singleCategory.category_is_active,
          };
        }
      } catch (err) {
        console.log("Error in getting  ", err);
      }
    },
  },
  beforeMount() {
    // console.log(this.$route.params);
    if (
      this.$route.params.category_id &&
      !isNaN(this.$route.params.category_id)
    ) {
      this.category_id = this.$route.params.category_id;
      this.getSingleCategory(this.category_id);
    }
  },
};
</script>

<style></style>
